
<script>
  import {
    Navbar,
    NavLeft,
    Link,
  } from 'framework7-svelte';
</script>

<Navbar sliding={false} transparent>
  <NavLeft>
    <Link 
      iconIos="f7:menu" 
      iconAurora="f7:menu" 
      iconMd="material:menu" 
      panelOpen="left" 
      iconSize="45px"/>
  </NavLeft>
</Navbar>
