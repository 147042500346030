<script>
  import {
    Swiper,
    SwiperSlide,
  } from 'framework7-svelte';

  let photos = [
    'static/present.png',
    'static/hoodie.png',
    'static/adams.png',
  ];
</script>

<div class="centered">
  <div></div>
<Swiper navigation class="swiperslide">
  {#each photos as photo}
    <SwiperSlide>
    <div>
    <img class="sliderimage" src={photo} 
         alt="ah ah ah, no magic image">
    </div>
    foto: Ketil Born
    </SwiperSlide>
  {/each}
  </Swiper>
<div></div>
</div>
