
<script>
  import {
    Swiper,
    SwiperSlide,
  } from 'framework7-svelte';

let videos = [
  'showreel2020.mp4',
  'oscarsgate.mp4',
]
</script>

<div>
  <div></div>
<div>
  <Swiper navigation>
    {#each videos as video}
      <SwiperSlide>
        <div class="videooutside">
          <video width="100%" class="videoslide" controls>
            <source src="static/{video}" type="video/mp4" class="videoinside">
          </video>
        </div>
      </SwiperSlide>
    {/each}
  </Swiper>
</div>
<div></div>
</div>
