
import HomePage from '../pages/home.svelte';
import About from '../pages/about.svelte';
import Gallery from '../pages/gallery.svelte';
import Showreels from '../pages/showreels.svelte';
import Contact from '../pages/contact.svelte';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/gallery',
    component: Gallery,
  },
  {
    path: '/showreels',
    component: Showreels,
  },
  {
    path: '/contact',
    component: Contact,
  },
];

export default routes;
