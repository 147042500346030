<script>
  import { onMount } from 'svelte';
  import routes from '../js/routes';
  import {
    f7ready,
    App,
    View,
  } from 'framework7-svelte';
  import HaraldPanel from '../components/haraldpanel.svelte'

  let f7params = {
    name: 'haraldveland.com',
    theme: 'auto',
    routes: routes,
  };

  onMount(() => { f7ready(() => {});})
</script>

<App params={ f7params } >

  <View main animate={false} name="main" id="main" class="safe-areas" url="/" />
  <HaraldPanel/>

</App>
